<script setup lang="ts">
import Icon from 'goout-icons/Icon.vue';
import Tooltip from 'goout-ui-kit/tooltip/Tooltip.vue';
import EventBoxActions from './EventBoxActions.vue';
import EventBoxImage from './EventBoxImage.vue';
import { getDateTimeFormat } from '~/utils';
import { computed } from 'vue';
import { getCurrentLocale } from '~/i18n';
import { EventBoxProps } from './EventBox.vue';

const { schedule } = defineProps<EventBoxProps>();
const name = computed(() => schedule.event.locales?.[getCurrentLocale()]?.name);
const venueComputed = computed(
  () =>
    schedule.venue.locales?.[getCurrentLocale()]?.name || schedule.venue.address
);

const openVenueNewTab = () => {
  const siteUrl = schedule.venue.locales?.[getCurrentLocale()]?.siteUrl ?? '';

  siteUrl
    ? window.open(siteUrl, '_blank')
    : console.warn('No valid venue site URL found for the current locale');
};

const eventImageUrl =
  schedule.event.images.at(0)?.url || 'https://via.placeholder.com/100';
</script>

<template>
  <tr>
    <td class="object-cover h-[120px] w-min-[168px] pr-6">
      <EventBoxImage
        v-if="eventImageUrl"
        :event-image-url="eventImageUrl"
        class="w-full h-24"
        :is-creator-event="schedule.source === 'creator'"
      />
    </td>
    <td>
      <p class="text-sm font-medium text-dark text-wrap">
        {{ name }}
      </p>
    </td>
    <td class="hidden md:table-cell">
      <Tooltip
        :text="$t('event.startAt')"
        position="top"
        rounded
        class="inline-block"
      >
        <span class="cursor-pointer text-gray-dark">{{
          getDateTimeFormat(schedule.startAt.toString())
        }}</span>
      </Tooltip>
    </td>

    <td
      class="text-gray-dark font-medium"
      :class="{
        'cursor-pointer': schedule.venue.locales?.[getCurrentLocale()]?.siteUrl,
      }"
      @click="openVenueNewTab"
    >
      {{ venueComputed }}
    </td>

    <td class="hidden 2xl:table-cell">
      <div
        class="flex items-center text-blue"
        :class="{ 'opacity-0': !schedule.publishedAt }"
      >
        <div>
          <Icon
            name="Clock"
            class="mt-[2px] text-blue/40 mr-2 block w-full h-full"
          />
        </div>
        <p class="font-medium">
          <span v-if="schedule.publishedAt">
            {{ getDateTimeFormat(schedule.publishedAt.toString()) }}
          </span>
        </p>
      </div>
    </td>

    <td>
      <EventBoxActions
        :id="schedule.event.id"
        class="flex items-center justify-end"
      />
    </td>
  </tr>
</template>

<style scoped lang="scss">
td {
  @apply text-sm text-wrap pt-4 text-left align-middle leading-4;
}

td:nth-child(2) {
  @apply pl-6;
}
td:not(:first-child) {
  @apply border-b border-snow-white pr-10;
}
p {
  @apply text-sm font-medium;
}
</style>
