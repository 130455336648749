{
    "btn.close": "Close",
    "btn.delete": "Confirm",
    "btn.send": "Send",
    "btn": {
        "continue": "Proceed",
        "ok": "Ok"
    },
    "category": {
        "button": {
            "confirm": {
                "selection": "Confirm"
            }
        },
        "change": {
            "categories": "Edit categories"
        },
        "tags": {
            "add": {
                "tags": "Add tags"
            },
            "noMatches": "Not found"
        }
    },
    "error.invalid_email": "Invalid e-mail",
    "error.invalid_url": "Invalid URL",
    "error.name_too_short": "This name is too short.",
    "error.required": "This field is required",
    "event.delete.description": "Are you sure you want to delete your event? This cannot be undone.",
    "event.delete.title": "Delete",
    "event.edit.description": "Right now, manual editing of events is not enabled. Please describe in detail what you would like to change. We will take care of it.",
    "event.edit.title": "Event edit",
    "event.list.title.past": "Past events",
    "event.list.title.published": "Published events",
    "event.list.title.scheduled": "Future events",
    "event.sell.description": "Please tell us your e-mail address and important details regarding your presale. These must include:\n\nNames of all ticket categories (can be just one)\nNumber of tickets for sale in each category\nTicket prices in each category\nThe beginning and end of the presale (to the hour)",
    "event.sell.title": "Set up event presale",
    "event.startAt": "Event starts at",
    "event": {
        "created": {
            "byGoout": "Event created by GoOut"
        },
        "edit": "Event editing",
        "organizer": "Event organizer",
        "sell": {
            "description": "Please tell us your e-mail address and important details regarding your presale. These must include:\n\nNames of all ticket categories (can be just one)\nNumber of tickets for sale in each category\nTicket prices in each category\nThe beginning and end of the presale (to the hour)",
            "title": "Set up event presale"
        },
        "startAt": "Event starts at"
    },
    "general.error.publish": "There was an error while publishing your event.",
    "general.error.title": "Error",
    "general": {
        "confirm": "Confirm",
        "contact": {
            "organizer": "Event organizer",
            "performer": "Artists"
        },
        "search": {
            "film": "Search database",
            "organizer": {
                "optional": "Search database (optional)"
            },
            "performer": {
                "optional": "Search database (optional)"
            },
            "venue": "Search database"
        },
        "tags": {
            "CANCELLED": "Cancelled",
            "CLASSICAL_MUSIC": "Classical music",
            "DEBATE": "Debate",
            "EXHIBITIONS_ART": "Art exhibitions",
            "EXHIBITIONS_ENDING_SOON": "Ending soon",
            "EXHIBITIONS_JUST_OPENED": "Recently opened",
            "EXHIBITIONS_UPCOMING": "Upcoming exhibitions",
            "NATURE": "Nature",
            "NATURE_PARK": "Park",
            "WORLD_MUSIC": "World music",
            "actors": "Actors",
            "actors_actresses": "Actors & Actresses",
            "actors_actressess": "Actors & Actresses",
            "actresses": "Actresses",
            "ad": "Audio descriptions",
            "ambient": "Ambient",
            "architecture": "Architecture and Urbanism",
            "article": "Magazine",
            "article_anquette": "Survey",
            "article_art": "Art",
            "article_cinema": "Cinema",
            "article_design": "Design",
            "article_fashion": "Fashion",
            "article_hm": "HM",
            "article_interviews": "Interviews",
            "article_introducing": "Introducing",
            "article_invites": "Invites",
            "article_literature": "Literature",
            "article_news": "News",
            "article_photoreports": "Photoreports",
            "article_points_of_interest": "Points of interest",
            "article_portraits": "Portraits",
            "article_reports": "Reports",
            "article_reviews": "Reviews",
            "article_series": "Series",
            "artists": "Artists",
            "augmented_reality": "Augmented reality",
            "bands": "Bands",
            "bar": "Bar",
            "blues": "Blues",
            "brewery": "Brewery",
            "cafe": "Cafe",
            "cafe_literary": "Literary",
            "cancelled": "Cancelled",
            "chanson": "Chanson",
            "charity": "Charity event",
            "choirs": "Choirs",
            "cinema": "Cinemas",
            "cinema_art": "Art-house cinema",
            "cinema_multiplex": "Multiplex",
            "cinema_open_air": "Outdoor cinemas",
            "cinema_single_auditorium": "Single auditorium",
            "classical": "Classical",
            "club": "Clubs",
            "clubbing": "Parties",
            "clubbing_indie": "Indie",
            "clubbing_latin": "Latin music",
            "comedian": "Comedians",
            "comedians": "Comedians",
            "comedy": "Comedy",
            "concert_hall": "Concert halls",
            "concerts": "Concerts",
            "concerts_blues": "Blues",
            "concerts_folklore": "Traditional folk",
            "concerts_gospel": "Gospel",
            "concerts_industrial": "Industrial",
            "concerts_latin_american": "Latin American music",
            "concerts_noise": "Noise",
            "concerts_release_party": "Release party",
            "concerts_soul": "Soul",
            "concerts_swing": "Swing",
            "concerts_trip_hop": "Trip hop",
            "conference": "Conference",
            "country": "Country",
            "cultural_centre": "Cultural centres",
            "cultural_centre_cultural_houses": "Cultural centre",
            "cultural_centre_national_centres": "National cultural centre",
            "current": "Upcoming",
            "dance": "Dance",
            "dernier": "Closing",
            "discussion": "Discussion",
            "djs": "DJs",
            "do_not_show": "Do not show",
            "documentary": "Documentary",
            "drama": "Drama",
            "drawings": "Drawings",
            "eco": "Eco",
            "electro": "Electro",
            "electro_swing": "Electro swing",
            "electronic": "Electronic",
            "english_friendly": "English friendly",
            "event": "Events",
            "exhibition_comics": "Comics",
            "exhibition_finissage": "Finissage",
            "exhibition_folk_art": "Folk art",
            "exhibition_objects": "Objects",
            "exhibition_site_specific": "Site-specific",
            "exhibition_sound_art": "Sound art",
            "exhibition_typography": "Typography",
            "exhibitions": "Exhibitions",
            "exhibitions_architecture": "Architecture and Urbanism",
            "exhibitions_classic": "Classic",
            "exhibitions_contemporary": "Contemporary art",
            "exhibitions_design": "Design",
            "exhibitions_fashion": "Fashion",
            "exhibitions_graphics": "Graphic exhibitions",
            "exhibitions_history": "History exhibitions",
            "exhibitions_illustrations": "Illustrations",
            "exhibitions_installations": "Installations",
            "exhibitions_mixed_media": "Mixed media",
            "exhibitions_monographic": "Monographic exhibitions",
            "exhibitions_new_media": "New media",
            "exhibitions_paintings": "Paintings",
            "exhibitions_performance": "Performance",
            "exhibitions_permanent": "Permanent Exhibitions",
            "exhibitions_photography": "Photography",
            "exhibitions_science_technology": "Science and technology",
            "exhibitions_sculptures": "Sculpture exhibitions",
            "exhibitions_street_art": "Street art",
            "exhibitions_vernisage": "Vernissage",
            "exhibitions_video": "Video",
            "experimental": "Experimental",
            "fairy_tale": "Fairy tale",
            "female_singers": "Female singers",
            "festival_permanent": "Permanent",
            "festivals": "Festivals",
            "festivals_art_design": "Art and design",
            "festivals_films": "Film festivals",
            "festivals_food": "Food festival",
            "festivals_multigenre": "Multigenre",
            "festivals_music": "Music",
            "festivals_other": "Others",
            "festivals_theatre": "Theatre",
            "film": "Movies",
            "film_action": "Action",
            "film_adventure": "Adventure",
            "film_animated": "Animated",
            "film_biographical": "Biographical",
            "film_biography": "Biography",
            "film_crime": "Crime",
            "film_czech": "Czech",
            "film_dance": "Dance film",
            "film_disaster": "Disaster film",
            "film_erotic": "Erotic",
            "film_exploitation": "Exploitation film",
            "film_family": "Family",
            "film_fantasy": "Fantasy",
            "film_for_children": "For children",
            "film_historic": "Historical",
            "film_horror": "Horror",
            "film_music": "Soundtrack",
            "film_mystery": "Mystery",
            "film_new": "New movies",
            "film_noir": "Noir",
            "film_psychological": "Psychological",
            "film_recording": "Recording",
            "film_road_movie": "Road movie",
            "film_romantic": "Romantic",
            "film_sci_fi": "Sci-Fi",
            "film_short": "Short",
            "film_student": "Student",
            "film_thriller": "Thriller",
            "film_war": "War",
            "film_western": "Western",
            "filterAll": "All",
            "filter_all": "Show all",
            "folk": "Contemporary folk",
            "folklore": "Folklore",
            "for_children": "For children",
            "for_children_educational": "Educational",
            "for_children_fun_experience": "Fun and experience",
            "for_me": "Recommended for you",
            "foreign": "Foreign",
            "free": "Free",
            "funk": "Funk",
            "gallery": "Galleries",
            "gallery_classical_art": "Classical art",
            "gallery_contemporary_art": "Contemporary art",
            "gallery_design": "Design",
            "gallery_modern_art": "Modern art",
            "gallery_photography": "Photography",
            "gallery_sculpture": "Sculpture",
            "gastronomy": "Culinary",
            "gastronomy_brunch": "Brunch",
            "gastronomy_courses": "Courses",
            "gastronomy_dinner": "Dinner",
            "gastronomy_market": "Markets",
            "gastronomy_tasting": "Tasting",
            "gastronomy_vege_vegan": "Vege and vegan",
            "gospel": "Gospel",
            "handicraft": "Handicraft",
            "hardcore": "Hardcore",
            "hip_hop": "Hip-Hop",
            "in_city": "Other events",
            "in_city_anniversary_party": "Celebration",
            "in_city_architecture": "Architecture and Urbanism",
            "in_city_art_and_design": "Art and Design",
            "in_city_auction": "Auction",
            "in_city_ball": "Ball",
            "in_city_burlesque": "Burlesque",
            "in_city_city_fashion": "Fashion",
            "in_city_city_markets": "City market",
            "in_city_course": "Course",
            "in_city_educational": "Educational",
            "in_city_fair": "Fair",
            "in_city_fun_and_experience": "Fun and Experience",
            "in_city_game": "Game",
            "in_city_guided_tour": "Guided tour",
            "in_city_history": "History",
            "in_city_literature": "Literature",
            "in_city_meeting": "Meeting",
            "in_city_networking": "Networking",
            "in_city_outdoor": "Outdoor activity",
            "in_city_poetry": "Poetry",
            "in_city_quiz": "Quiz",
            "in_city_release": "Release party",
            "in_city_science_tech": "Science and technology",
            "in_city_sport": "Sport",
            "in_city_swap": "Swap",
            "in_city_talk_show": "Talk show",
            "in_city_travels": "Travel",
            "in_city_walk": "City walk",
            "in_city_webinar": "Webinar",
            "in_city_well_being": "Well-being",
            "indie": "Alternative\/Indie",
            "indoor_space": "Indoor space",
            "jam_session": "Jam session",
            "jazz": "Jazz",
            "k_pop": "K-pop",
            "latin_american": "Latin music",
            "liked": "Saved",
            "local": "Local",
            "male_singers": "Singers",
            "metal": "Metal",
            "museum": "Museums",
            "museum_historical": "Historical",
            "museum_natural_science": "Natural science",
            "museum_observatory": "Observatory",
            "museum_technical": "Technical",
            "music": "Music",
            "musical": "Musical",
            "musicians": "Musicians",
            "new": "New",
            "new_age": "New Age",
            "newly_announced": "Newly added",
            "noise": "Noise",
            "online": "Online events",
            "open_decks": "Open decks",
            "orchestras": "Orchestras",
            "order_by_friends": "Friends liked",
            "order_by_start": "Upcoming",
            "organizer": "Event Organizer",
            "other_venue": "Other venues",
            "partner_internal": "Internal partner",
            "party_breakbeat": "Breakbeat",
            "party_dancehall": "Dancehall",
            "party_disco": "Disco",
            "party_drum_and_bass": "Drum and bass",
            "party_dub": "Dub",
            "party_dubstep": "Dubstep",
            "party_edm": "EDM",
            "party_grime": "Grime",
            "party_hardstyle": "Hardstyle",
            "party_house": "House",
            "party_oldies": "Oldies",
            "party_techno": "Techno",
            "party_trance": "Trance",
            "party_trap": "Trap",
            "past": "Past",
            "performer": "Artists",
            "performers_top": "Recommended Artists",
            "performing": "Performer",
            "photographers": "Photographers",
            "play": "Plays",
            "play_alternative": "Alternative",
            "play_ballet": "Ballet",
            "play_black": "Black theatre",
            "play_cabaret": "Cabaret",
            "play_dance": "Contemporary dance",
            "play_for_schools": "For schools",
            "play_immersive": "Immersive theater",
            "play_improvisation": "Improvisation",
            "play_literature": "Literature",
            "play_magic": "Magic",
            "play_melodrama": "Melodrama",
            "play_musical": "Music",
            "play_new_circus": "Contemporary circus",
            "play_one_person_show": "One-person show",
            "play_opera": "Opera",
            "play_pantomime": "Pantomime",
            "play_performance": "Performance",
            "play_physical": "Physical theater",
            "play_puppets": "Puppetry",
            "play_scenic_reading": "Stage reading",
            "play_slam_poetry": "Slam poetry",
            "play_stand_up_comedy": "Stand-up comedy",
            "play_tragedy": "Tragedy",
            "pop": "Pop",
            "popular": "Trending",
            "post_punk": "Post-punk",
            "postponed": "Postponed",
            "postponed_indefinitely": "Postponed indefinitely",
            "premier": "Premiere",
            "projection": "Screening",
            "pub": "Pub",
            "public_person": "Influencers",
            "public_persons": "Influencers",
            "public_rehersal": "Public rehearsal",
            "public_space": "Public space",
            "punk": "Punk",
            "r_n_b": "R'n'B",
            "rap": "Rap",
            "rappers": "Rappers",
            "recommended": "Recommended",
            "recommended_in_category": "Featured in main category",
            "recording": "Recording",
            "reggae": "Reggae",
            "restaurant": "Restaurant",
            "restaurant_czech": "Czech restaurant",
            "restaurant_italian": "Italian restaurant",
            "rock": "Rock",
            "sale_on_venue": "Tickets at venue",
            "schedule": "Schedule",
            "schedule_3d": "3D",
            "schedule_all_time": "Entire period",
            "schedule_calendar": "Select dates",
            "schedule_month": "This month",
            "schedule_next_week": "Next week",
            "schedule_next_year": "Next year",
            "schedule_today": "Today",
            "schedule_tomorrow": "Tomorrow",
            "schedule_week": "This week",
            "schedule_weekend": "This weekend",
            "schedule_year": "This year",
            "sdh": "Subtitles for the Deaf and Hard of Hearing",
            "show": "Show",
            "sight": "Sight",
            "sight_castle": "Castle",
            "sight_palace": "Palace",
            "sight_summer_house": "Summer house",
            "ska": "Ska",
            "sold_out": "Sold out",
            "soul": "Soul",
            "sound_dubbed": "Dubbed",
            "sound_original": "Original soundtrack",
            "sport": "Sport",
            "sport_venue": "Sport venue",
            "storytelling": "Storytelling",
            "swing": "Swing",
            "talk": "Talk",
            "tea_room": "Tea room",
            "tech_house": "Tech house",
            "template": "Template",
            "theatre": "Theaters",
            "theatre_alternative": "Alternative",
            "theatre_dance": "Contemporary dance",
            "theatre_groups": "Theatre companies",
            "theatre_music": "Music",
            "theatre_opera": "Opera",
            "theatres": "Theaters",
            "trap": "Trap",
            "trip_hop": "Trip hop",
            "uk_bass": "UK bass",
            "venue": "Venues",
            "venue_top": "Top venue",
            "voluntary": "Voluntary",
            "workshop": "Workshop"
        },
        "tip": "Tip"
    },
    "image": {
        "choose": {
            "three": {
                "max": {
                    "five": "Use photos that are at least 1000 × 670 px. Photos should not include any text or watermarks."
                }
            }
        },
        "copyright": "Copyright",
        "drag": {
            "zone": "Drag and drop"
        },
        "edit": {
            "title": "Edit photo"
        },
        "name": "Name",
        "origin": "Source",
        "save": "Save",
        "upload": {
            "helper": {
                "text": "We have observed that the best way to present your event is to include photos of performing artists, bands, or groups. You can also upload movie screenshots or event photos (exhibitions, theater plays). Do not use logos, album covers, posters, etc."
            },
            "link": "Upload from computer"
        }
    },
    "images.error.too.many": "There are too many images.",
    "images.error.upload": "There was an error uploading this image.",
    "language": {
        "cs": "Česky",
        "en": "English"
    },
    "layout.mainPage.addNewEventButton": "Add new event",
    "layout.mainPage.myEvents": "My events",
    "layout.mainPage.showLess": "Show less",
    "layout.mainPage.showMore": "Show more",
    "layout": {
        "mainPage": {
            "showLess": "Show less",
            "showMore": "Show more"
        }
    },
    "modal.addSale.template": "Please set up the presale as follows:\nPresale start (date+time):\nPresale end (date+time):\nSales category name:\nSales prices (CZK):\nSales category capacity:",
    "modal.confirm.delete.desc": "This cannot be undone.",
    "modal.confirm.delete.title": "Are you sure you want to delete your event?",
    "modal.confirm.edit.desc": "All changes are saved.",
    "modal.confirm.edit.title": "Your event was edited",
    "modal.confirm.sell.desc": "Your message is ready to be sent.",
    "modal.confirm.sell.title": "Are you sure you wish to proceed?",
    "modal.error.delete.desc": "Please try again.",
    "modal.error.delete.title": "Something went wrong",
    "modal.error.edit.desc": "Please try again.",
    "modal.error.edit.title": "Something went wrong",
    "modal.error.sell.desc": "Please try again.",
    "modal.error.sell.title": "Something went wrong",
    "modal.success.delete.desc": "Your event was deleted.",
    "modal.success.delete.title": "Success",
    "modal.success.edit.desc": "Thank you! We will get in touch with you within 24 hours.",
    "modal.success.edit.title": "Message sent",
    "modal.success.sell.desc": "Thank you! We will get in touch with you within 24 hours.",
    "modal.success.sell.title": "Message sent",
    "modal": {
        "event": {
            "delete": "Delete",
            "edit": "Edit"
        },
        "schedule": {
            "publish": {
                "confirm": "Confirm",
                "date": "Date",
                "time": "Time",
                "title": "Schedule publishing"
            }
        }
    },
    "overview": {
        "feedback": {
            "title": "Give us feedback"
        },
        "give": {
            "us": {
                "feedback": {
                    "text": "We want to improve for you and your feedback is important"
                }
            }
        }
    },
    "promoter": {
        "form": {
            "field": {
                "country": {
                    "option": {
                        "cz": "Czechia",
                        "de": "Germany",
                        "pl": "Poland",
                        "sk": "Slovakia"
                    }
                }
            }
        }
    },
    "section": {
        "category": {
            "mainCategory": "Main category",
            "setMain": "Set as the main category"
        },
        "photo": {
            "label": "Image label"
        }
    },
    "tab.category": "Category",
    "tab.date": "Event date",
    "tab.image": "Photos",
    "tab.metadata_film": "Film",
    "tab.metadata_play": "Plays",
    "tab.organizer": "Event organizer",
    "tab.performer": "Artists",
    "tab.publish": "Publish",
    "tab.venue": "Venue",
    "tab": {
        "general": "Basic info"
    },
    "tabs": {
        "publish": {
            "summary": "Event overview"
        }
    },
    "topbar": {
        "logout": "Log out"
    },
    "venue.input": "Venue",
    "wizard.intro.firstEvent.addYourEvent": "We have launched a new function:",
    "wizard.intro.firstEvent.button": "I want that!",
    "wizard.intro.firstEvent.title": "Add your event to GoOut",
    "wizard.intro.steps.one.perex": "And — of course — why people shouldn't miss it!",
    "wizard.intro.steps.one.title": "Tell us who, where, and when",
    "wizard.intro.steps.three.perex": "In additional steps, you can also contact us about ticket presale.",
    "wizard.intro.steps.three.title": "Upload your event directly to GoOut",
    "wizard.intro.steps.two.perex": "You can fill in details about event organizers, genres, and more.",
    "wizard.intro.steps.two.title": "Add photos and other info",
    "wizard.tabs.category.perex": "Select any number of categories for your event. You can also add genre tags here.",
    "wizard.tabs.category.title": "Category",
    "wizard.tabs.film.countryIsos": "Origin",
    "wizard.tabs.film.director": "Director",
    "wizard.tabs.film.length": "Length",
    "wizard.tabs.film.minorPerformers": "Cast",
    "wizard.tabs.film.originalName": "Original name",
    "wizard.tabs.film.released": "Release",
    "wizard.tabs.film.search": "Search database",
    "wizard.tabs.film.tags": "Movie genre",
    "wizard.tabs.metadata_film.perex": "Please write out relevant information about the film.",
    "wizard.tabs.metadata_film.title": "Film details",
    "wizard.tabs.metadata_play.perex": "Please write out relevant information about the play.",
    "wizard.tabs.metadata_play.title": "Play details",
    "wizard.tabs.play.actors": "Cast",
    "wizard.tabs.play.author": "Author",
    "wizard.tabs.play.director": "Director",
    "wizard.tabs.play.length": "Length",
    "wizard.tabs.play.name": "Title",
    "wizard.tabs.play.premiere": "Release",
    "wizard": {
        "back": "Back",
        "editor": {
            "bold": "Bold",
            "h1": "Headline 1",
            "h2": "Headline 2",
            "h3": "Headline 3",
            "italic": "Italic",
            "listOrdered": "Ordered list",
            "listUnordered": "Unordered list",
            "underline": "Underscore"
        },
        "intro": {
            "firstEvent": {
                "button": "I want that!"
            },
            "steps": {
                "one": {
                    "perex": "And — of course — why people shouldn't miss it!",
                    "title": "Tell us who, where, and when"
                },
                "three": {
                    "perex": "In additional steps, you can also contact us about ticket presale.",
                    "title": "Upload your event directly to GoOut"
                },
                "two": {
                    "perex": "You can fill in details about event organizers, genres, and more.",
                    "title": "Add photos and other info"
                }
            },
            "title": "Add your event to GoOut"
        },
        "next": "Next",
        "publish": "Publish event",
        "schedule": {
            "publishing": "Schedule publishing"
        },
        "tabs": {
            "date": {
                "add": "Add more dates",
                "end": {
                    "date": "Last day",
                    "time": "Ends at"
                },
                "perex": "When does your event take place?",
                "start": {
                    "date": "First day",
                    "time": "Starts at"
                },
                "title": "Date and time"
            },
            "general": {
                "categories": "Categories",
                "description": "Event description",
                "editor": {
                    "optional": "Event description (optional)"
                },
                "name": "Event name",
                "perex": "Fill in the name of your event. We also recommend adding a few words about the event itself.",
                "performers": "Artists",
                "tags": "Tags",
                "title": "Basic information",
                "venue": "Venue",
                "web": {
                    "optional": "Event website (optional)"
                }
            },
            "image": {
                "perex": "Each new event has to have at least one photo. The recommended number of photos is 3 to 5. The maximum number is 5.",
                "title": "Add photos"
            },
            "organizer": {
                "perex": "Who is running your event? Do you have a GoOut profile as an event organizer? Or are you filling in an event in the name of a registered organizer?",
                "title": "Event organizer"
            },
            "performer": {
                "perex": "You can select the performers from our database. This field is optional.",
                "title": "Add performers"
            },
            "publish": {
                "categories": "Category",
                "description": "Event description",
                "name": "Event name",
                "perex": "Make sure everything is OK before you publish your event. We will then validate it as soon as possible.",
                "performers": "Artists",
                "tags": "Tags",
                "title": "Final check",
                "venue": "Venue",
                "web": "Event website"
            },
            "venue": {
                "perex": "Select a venue before proceeding. You can add any venue from our database.",
                "title": "Venue"
            }
        }
    }
}