import { parse } from 'date-fns';
import { DateSchema } from '~/definitions/schemas/date/date.schema';
import { getCurrentLocale } from '~/i18n';

export function getDateTimeFormat(date: string) {
  return `${new Date(date).toLocaleDateString()} ${new Date(
    date
  ).toLocaleTimeString(getCurrentLocale(), {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
}

export function getDateFormat(date: string) {
  return `${new Date(date).toLocaleDateString(getCurrentLocale())}`;
}

/* Convert a string to valid date */
export function parseDate(
  date: DateSchema['startDate'],
  time: DateSchema['startTime']
): Date {
  const paddedTime = padTime(time);
  return parse(
    `${date} ${paddedTime.hour}:${paddedTime.minute}`,
    'yyyy-MM-dd HH:mm',
    new Date()
  );
}

function padTime(time: DateSchema['startTime']) {
  return {
    hour: String(time.hour).padStart(2, '0'),
    minute: String(time.minute).padStart(2, '0'),
  };
}
